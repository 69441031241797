<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<launch_nav :site="site" :preview="preview" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="w-100 h-100 position-absolute top-0 start-0 p-4 d-flex align-items-start overflow-auto" style="z-index: 1">

						<div class="container">

							<div class="row h-100 justify-content-center mb-4">

								<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

									<form @submit.prevent="submit" class="w-100 mx-auto">

										<div class="card">

											<div class="card-body p-4">

												<div class="alert alert-info small">
													Rather than manually creating htaccess rules and adding 301 redirects for each of your pages, turn on automatic redirects to automatically forward all 404's to the most relevant page.
												</div>

												<div class="list-group mb-3 w-100">
													<label  
													:for="'include-redirects'" 
													class="list-group-item d-flex align-items-start"
													>
														<span class="me-3">
															<span class="material-icons-outlined">swap_horiz</span>
														</span>
														<span class="me-4 flex-grow-1">
															<p class="fw-bold mb-0">Include automatic redirects</p>
															<p class="form-text mb-0">If checked 404 pages will automatically redirect to the most relevant page.</p>
														</span>
														<div class="form-check form-switch">
															<input 
															:name="'include-redirects'" 
															class="form-check-input" 
															type="checkbox" 
															role="switch" 
															:id="'include-redirects'" 
															v-model="site.settings['include-redirects']"
															>
														</div>
														
													</label>
												</div>

												<btn_submit 
													label="Update redirects" 
													icon="check"  
													:icon_after="true" 
													:loading="loading" 
												/>	

												<a :href="preview + '/wp-admin/admin.php?page=aaio-dashboard&section=redirects'" target="_blank" class="mt-4 d-inline-block">Manually add redirects</a>
											</div>
										</div>

									</form>

								</div>

							</div>

						</div>
					</div>				

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import launch_nav from '@/components/launch_nav'
import btn_submit from '@/components/btn_submit'
import site_service from '@/services/site_service'

export default {
	name: 'site.redirects',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		launch_nav,
		btn_submit,
	},

	data() {
		return {
			loading: false,
			site: null,
			version: 0,
			wp_pages: [],
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'redirects'

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},
	}
}
</script>